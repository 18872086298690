import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/ephys/Development/reworkjs/core/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "reworkjs"
    }}><a parentName="h1" {...{
        "href": "https://www.reworkjs.com/"
      }}>{`rework.js`}</a></h1>
    <p><em parentName="p">{`Opinionated React Framework`}</em></p>
    <p>{`rework.js is an opinionated, extensible, Framework designed for Progressive Web Apps.`}</p>
    <p>{`The main motivation for this project is to separate the boilerplate from the project code. Configure the boilerplate, don't write it.`}</p>
    <h2 {...{
      "id": "principles"
    }}>{`Principles`}</h2>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Familiar`}</strong>{`: This framework follows community conventions and is regularly updated with new ideas from the community.`}</li>
      <li parentName="ul"><strong parentName="li">{`Minimise boilerplate code`}</strong>{`: Boilerplate code is difficult to update when you have multiple projects, you need to copy-paste a lot. Most people won't do it and lose out on new awesome features as the tech progresses.`}</li>
      <li parentName="ul"><strong parentName="li">{`Optimized`}</strong>{`: In production, the app should run as fast as it can. Even on slow devices.`}</li>
      <li parentName="ul"><strong parentName="li">{`Modular`}</strong>{`: The code should not be split by type of technology but by features.`}</li>
      <li parentName="ul"><strong parentName="li">{`Extensible`}</strong>{`: Need something not provided by core? You can install or write a plugin for that!`}</li>
      <li parentName="ul"><strong parentName="li">{`Universal`}</strong>{`: Your app should be able to be server-side rendered, or run as a standalone.`}</li>
    </ul>
    <h2 {...{
      "id": "main-features"
    }}>{`Main Features`}</h2>
    <ul>
      <li parentName="ul">{`Hot Reloading`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.reworkjs.com/routing#code-splitting--lazy-loading"
        }}>{`Code Splitting`}</a></li>
      <li parentName="ul">{`Built-in support for `}<inlineCode parentName="li">{`.browserslistrc`}</inlineCode></li>
      <li parentName="ul">{`Supports `}<a parentName="li" {...{
          "href": "https://www.reworkjs.com/typescript"
        }}>{`TypeScript`}</a></li>
      <li parentName="ul">{`Built-in support for `}<a parentName="li" {...{
          "href": "https://www.reworkjs.com/styling"
        }}>{`CSS Modules, SCSS & PostCSS`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.reworkjs.com/server-side-rendering"
        }}>{`Server-Side Rendering`}</a>{` ready`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.reworkjs.com/i18n"
        }}>{`Automatic Locale Management`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.reworkjs.com/public-resources"
        }}>{`Static and dynamic assets`}</a></li>
      <li parentName="ul">{`And much more!`}</li>
    </ul>
    <h2 {...{
      "id": "projects-built-with-rework"
    }}>{`Projects built with rework`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/ephys/santa.be"
        }}>{`santa.be`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      